import {css} from '@emotion/react';

// language=SCSS
export const paddingCss = css`
  & {
    padding: var(--spacer);
  }
`;

// language=SCSS
export const widthCss = css`
  & {
    width: calc(300rem / var(--bfs));
  }
`;
