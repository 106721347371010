import {
  IFilter,
  IFilterDescription,
  isAutoCompleteFilter,
  isEnumFilter,
  isEnumLazyFilter,
  isRangeFilter,
  isSearchFilter,
  isTextFilter,
  isTimeFilter
} from './filterTypes';

export const getFilterDefaults = <K extends string, FD extends IFilterDescription<K>, F extends IFilter<K, FD>>(
  filterDescription: FD
): F => {
  const fdEntries = Object.entries(filterDescription) as Array<[K, FD[K]]>;
  const defaults = fdEntries.reduce(<_K extends K>(acc: Array<[K, F[K]]>, [key, value]: [_K, FD[_K]]) => {
    let initial;
    if (isTextFilter(value) || isAutoCompleteFilter(value)) {
      initial = '';
    } else if (isRangeFilter(value) || isTimeFilter(value)) {
      initial = [-Infinity, Infinity];
    } else if (isEnumFilter(value) || isEnumLazyFilter(value) || isSearchFilter(value)) {
      initial = [];
    }
    if (typeof initial !== 'undefined') {
      acc.push([key, initial as F[_K]]);
    } else {
      console.error('Invalid filter description', value);
    }
    return acc;
  }, [] as Array<[K, F[K]]>);
  return (Object.fromEntries(defaults) as unknown) as F;
};
