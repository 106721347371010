/** @jsx jsx */
import {jsx} from '@emotion/react';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AutoComplete, AutoCompleteProps} from 'primereact/autocomplete';
import {paddingCss, widthCss} from './styles';
import {FilterActions} from '../FilterActions';
import {IFilterFieldType, ISearchFilter} from '../../filterTypes';
import {useNextFilter, useSetNextFilter} from '../../hooks/nextFilter';
import {Def, IOption} from '../../utils/types';
import {useMountedRef} from '../../hooks/useMountedRef';
import {Chips, ChipsProps} from 'primereact/chips';
import {isEqual} from 'lodash-es';

type IValue = IFilterFieldType<ISearchFilter>;

export function SearchFilter({field, description}: {field: string; description: ISearchFilter}) {
  const {searchOptions, placeholder, minLength, maxEntries, getOptionsByValues} = description;
  const mountedRef = useMountedRef();
  const nextFilter = useNextFilter();
  const setNextFilter = useSetNextFilter();
  const value = nextFilter[field] as IValue;
  const updateValue = useCallback(
    (newVal: IValue) => {
      setNextFilter((prev) => {
        return {
          ...prev,
          [field]: newVal
        };
      });
    },
    [setNextFilter, field]
  );
  const [suggestions, setSuggestions] = useState<IOption<string | number>[]>([]);
  const [localValue, setLocalValue] = useState<IOption<string | number>[]>([]);
  const completeMethod = useCallback<Def<AutoCompleteProps['completeMethod']>>(
    ({query}) => {
      searchOptions(query)
        .then((res) => {
          if (mountedRef.current) {
            setSuggestions(res);
          }
        })
        .catch(console.error);
    },
    [searchOptions, mountedRef]
  );
  const onComplete = useCallback<Def<AutoCompleteProps['onChange']>>((e) => {
    if (Array.isArray(e.value)) {
      setLocalValue(e.value);
    } else {
      setLocalValue([]);
    }
  }, []);
  const onRemove = useCallback<Def<ChipsProps['onChange']>>((e) => {
    const set = new Set(e.value);
    setLocalValue((prev) => prev.filter((v) => set.has(v.label)));
  }, []);
  const localValueList = useMemo(() => localValue.map((v) => v.value), [localValue]);
  const isFirstRef = useRef(true);
  useEffect(() => {
    if (isFirstRef.current) {
      isFirstRef.current = false;
    } else {
      updateValue(localValueList);
    }
  }, [localValueList, updateValue]);
  useEffect(() => {
    if (!isEqual(localValueList.sort(), value.sort())) {
      if (value.length > 0) {
        getOptionsByValues(value).then((options) => {
          if (mountedRef.current) {
            setLocalValue(options);
          }
        });
      } else {
        setLocalValue([]);
      }
    }
    // eslint-disable-next-line
  }, [value, getOptionsByValues, mountedRef]);
  return (
    <div css={[paddingCss, widthCss]}>
      <div className={'p-fluid p-input-filled'}>
        {localValue.length >= maxEntries ? (
          <Chips max={maxEntries} value={localValue.map((v) => v.label)} onChange={onRemove} />
        ) : (
          <AutoComplete
            multiple
            field={'label'}
            placeholder={placeholder}
            value={localValue}
            suggestions={suggestions}
            minLength={minLength}
            completeMethod={completeMethod}
            onChange={onComplete}
          />
        )}
      </div>
      <FilterActions field={field} />
    </div>
  );
}
