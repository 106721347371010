import {
  isArray,
  isExact,
  isFunction,
  isNumber,
  isShape,
  isString,
  isUndefined,
  or,
  refine
} from './utils/basicValidators';
import {checkMinMax} from './utils/checkMinMax';
import {IOption} from './utils/types';

const isDefNumber = refine(isNumber, (v) => !isNaN(v));

export type IFilterDescription<K extends string> = {
  [P in K]?:
    | ITextFilter
    | IRangeFilter
    | ITimeFilter
    | IEnumFilter
    | IEnumLazyFilter
    | ISearchFilter
    | IAutoCompleteFilter;
};

export type IFilter<K extends string, FD extends IFilterDescription<K>> = {
  [P in keyof FD]: IFilterFieldType<FD[P]>;
};

export type IFilterFieldType<T> = T extends ITextFilter
  ? string
  : T extends IRangeFilter
  ? [number, number]
  : T extends ITimeFilter
  ? [number, number]
  : T extends IEnumFilter
  ? Array<string | number>
  : T extends IEnumLazyFilter
  ? Array<string | number>
  : T extends ISearchFilter
  ? Array<string | number>
  : T extends IAutoCompleteFilter
  ? string
  : never;

export interface ITextFilter {
  type: 'text';
  field?: string;
  placeholder: string;
  transform?: (v: IFilterFieldType<ITextFilter>) => IFilterFieldType<ITextFilter>;
}

export const isTextFilter = isShape<ITextFilter>({
  type: isExact('text'),
  field: or(isUndefined, isString),
  placeholder: isString,
  transform: or(isFunction, isUndefined)
});

export interface ITimeFilter {
  type: 'time';
  field?: string;
  min?: number | 'now';
  max?: number | 'now';
}

const isTimeLimit = or(isDefNumber, refine(isString, isExact<'now'>('now')));

export const isTimeFilter = refine(
  isShape<ITimeFilter>({
    type: isExact('time'),
    field: or(isUndefined, isString),
    min: or(isUndefined, isTimeLimit),
    max: or(isUndefined, isTimeLimit)
  }),
  (v) => (isNumber(v.min) && isNumber(v.max) ? checkMinMax(v.min, v.max) : true)
);

export interface IEnumFilter {
  type: 'enum';
  field?: string;
  options: IOption<string | number>[];
}

export const isEnumFilter = isShape<IEnumFilter>({
  type: isExact('enum'),
  field: or(isUndefined, isString),
  options: isArray(
    isShape<IOption<string | number>>({label: isString, value: or(isString, isNumber)})
  )
});

export interface IEnumLazyFilter {
  type: 'enumLazy';
  field?: string;
  getOptions: () => Promise<IOption<string | number>[]>;
}

export const isEnumLazyFilter = isShape<IEnumLazyFilter>({
  type: isExact('enumLazy'),
  field: or(isUndefined, isString),
  getOptions: isFunction
});

export interface IRangeFilter {
  type: 'range';
  field?: string;
  min: number;
  max: number;
  step?: number;
  template?: string;
  toFixed?: number;
  noneText: string;
  anyText: string;
}

export const isRangeFilter = refine(
  isShape<IRangeFilter>({
    type: isExact('range'),
    field: or(isUndefined, isString),
    min: isDefNumber,
    max: isDefNumber,
    step: or(
      refine(isDefNumber, (v) => v > 0),
      isUndefined
    ),
    template: or(
      refine(isString, (v) => v.includes('{{1}}') && v.includes('{{2}}')),
      isUndefined
    ),
    toFixed: or(
      refine(isDefNumber, (v) => v >= 0),
      isUndefined
    ),
    noneText: isString,
    anyText: isString
  }),
  (v) => checkMinMax(v.min, v.max)
);

export interface ISearchFilter {
  type: 'search';
  field?: string;
  placeholder: string;
  maxEntries: number;
  minLength: number;
  getOptionsByValues: (values: Array<string | number>) => Promise<Array<IOption<string | number>>>;
  searchOptions: (query: string) => Promise<Array<IOption<string | number>>>;
}

export const isSearchFilter = isShape<ISearchFilter>({
  type: isExact('search'),
  field: or(isUndefined, isString),
  placeholder: isString,
  maxEntries: refine(isDefNumber, (v) => v > 0),
  minLength: refine(isDefNumber, (v) => v >= 1),
  getOptionsByValues: isFunction,
  searchOptions: isFunction
});

export interface IAutoCompleteFilter {
  type: 'autocomplete';
  field?: string;
  placeholder: string;
  minLength: number;
  searchOptions: (query: string) => Promise<string[]>;
}

export const isAutoCompleteFilter = isShape<IAutoCompleteFilter>({
  type: isExact('autocomplete'),
  field: or(isUndefined, isString),
  placeholder: isString,
  minLength: refine(isDefNumber, (v) => v >= 1),
  searchOptions: isFunction
});
