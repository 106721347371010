/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';

const fillTextColorCss = css`
  & {
    fill: var(--text-color-secondary);
  }
`;

export const Tune: FC = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.35 10C9.76574 10 8.43455 8.88457 8.05712 7.375L2 7.375L2 5.625L8.05712 5.625C8.43455 4.11543 9.76574 3 11.35 3C13.2278 3 14.75 4.567 14.75 6.5C14.75 8.433 13.2278 10 11.35 10ZM13.05 6.5C13.05 7.4665 12.2889 8.25 11.35 8.25C10.4111 8.25 9.65 7.4665 9.65 6.5C9.65 5.5335 10.4111 4.75 11.35 4.75C12.2889 4.75 13.05 5.5335 13.05 6.5Z"
        fill="#959DA8"
        css={fillTextColorCss}
      />
      <path d="M15.6 5.625L18 5.625L18 7.375L15.6 7.375L15.6 5.625Z" fill="#959DA8" css={fillTextColorCss} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1 17C5.51574 17 4.18455 15.8846 3.80711 14.375L2 14.375L2 12.625L3.80711 12.625C4.18455 11.1154 5.51574 10 7.1 10C8.97777 10 10.5 11.567 10.5 13.5C10.5 15.433 8.97777 17 7.1 17ZM8.8 13.5C8.8 14.4665 8.03888 15.25 7.1 15.25C6.16112 15.25 5.4 14.4665 5.4 13.5C5.4 12.5335 6.16112 11.75 7.1 11.75C8.03888 11.75 8.8 12.5335 8.8 13.5Z"
        fill="#959DA8"
        css={fillTextColorCss}
      />
      <path d="M11.35 14.375L18 14.375L18 12.625L11.35 12.625L11.35 14.375Z" fill="#959DA8" css={fillTextColorCss} />
    </svg>
  );
};
