/** @jsx createElement */
import {
  createContext,
  createElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  useFilter,
  useFilterDefaults,
  useSelectedColumns,
  useSetFilter
} from './tableState';
import { IFilter, IFilterDescription } from '../filterTypes';
import { IUpdater } from '../utils/types';
import { updateValues } from '../utils/entries';

const DefaultFilterCtx = createContext<IFilter<any, any>>(null!);

export const useDefaultFilter = <K extends string>(): IFilter<K, IFilterDescription<K>> => useContext(DefaultFilterCtx);

const NextFilterCtx = createContext<IFilter<any, any>>(null!);

export const useNextFilter = <K extends string, FD extends IFilterDescription<K>>(): IFilter<K, FD> =>
  useContext(NextFilterCtx);

const SetNextFilterCtx = createContext<IUpdater<IFilter<any, any>>>(null!);

export const useSetNextFilter = <K extends string, FD extends IFilterDescription<K>>(): IUpdater<
  IFilter<K, IFilterDescription<K>>
> => useContext(SetNextFilterCtx);

const ApplyFilterCtx = createContext<(field?: any) => void>(null!);
export const useApplyFilter = <K extends string>(): ((field?: K) => void) => useContext(ApplyFilterCtx);

const CancelFilterCtx = createContext<(field?: any) => void>(null!);
export const useCancelFilter = <K extends string>(): ((field?: K) => void) => useContext(CancelFilterCtx);

const ResetFilterCtx = createContext<(field?: any) => void>(null!);
export const useResetFilter = <K extends string>(): ((field?: K) => void) => useContext(ResetFilterCtx);

export function NextFilterProvider<K extends string, FD extends IFilterDescription<K>>({
  children
}: {
  children: ReactNode;
}) {
  const selectedColumns = useSelectedColumns();
  const filter = useFilter<K, FD>();
  const filterRef = useRef<typeof filterDefaults>(filter);
  filterRef.current = filter;
  const setFilter = useSetFilter<K, FD>();
  const filterDefaults = useFilterDefaults<K, FD>();
  const [nextFilter, setNextFilter] = useState(filterDefaults);
  const nextFilterRef = useRef<typeof filterDefaults>(filterDefaults);
  nextFilterRef.current = nextFilter;
  // reset nextFilter on filter change
  useEffect(() => {
    setNextFilter(filter);
  }, [filter]);
  // reset nextFilter fields that are not in selected columns
  useEffect(() => {
    setNextFilter((filter) => {
      const activeFilters = new Set(selectedColumns.map((col) => col.field));
      return updateValues(filter, (value, key) => (activeFilters.has(key as string) ? value : filterDefaults[key]));
    });
  }, [selectedColumns, filterDefaults]);
  const applyFilter = useCallback(
    (field?: K) => {
      if (typeof field === 'string') {
        setFilter((prev) => ({
          ...prev,
          [field]: nextFilterRef.current[field]
        }));
      } else {
        setFilter(() => nextFilterRef.current);
      }
    },
    [setFilter]
  );
  const cancelFilter = useCallback((field?: K) => {
    if (typeof field === 'string') {
      setNextFilter((prev) => {
        return {
          ...prev,
          [field]: filterRef.current[field]
        };
      });
    } else {
      setNextFilter(filterRef.current);
    }
  }, []);
  const resetFilter = useCallback(
    (field?: K) => {
      if (typeof field === 'string') {
        setFilter((prev) => ({
          ...prev,
          [field]: filterDefaults[field]
        }));
      } else {
        setFilter(() => filterDefaults);
      }
    },
    [setFilter, filterDefaults]
  );
  return (
    <DefaultFilterCtx.Provider value={filterDefaults}>
      <NextFilterCtx.Provider value={nextFilter}>
        <SetNextFilterCtx.Provider value={setNextFilter}>
          <ApplyFilterCtx.Provider value={applyFilter}>
            <CancelFilterCtx.Provider value={cancelFilter}>
              <ResetFilterCtx.Provider value={resetFilter}>{children}</ResetFilterCtx.Provider>
            </CancelFilterCtx.Provider>
          </ApplyFilterCtx.Provider>
        </SetNextFilterCtx.Provider>
      </NextFilterCtx.Provider>
    </DefaultFilterCtx.Provider>
  );
}
