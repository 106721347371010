import { ISimpleObservable } from '../utils/simpleObservable';
import { useEffect, useState } from 'react';

export const useObservable = <T>(obs: ISimpleObservable<T>): T => {
  const [value, setValue] = useState<T>(obs.get);
  useEffect(() => {
    setValue(obs.get());
    return obs.subscribe(setValue);
  }, [obs]);
  return value;
};
