/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useCallback, useEffect, useState} from 'react';
import {useLocale} from '../../hooks/locale';
import {FilterActions} from '../FilterActions';
import {widthCss} from './styles';
import {useNextFilter, useSetNextFilter} from '../../hooks/nextFilter';
import {CheckboxField, ICheckboxFieldProps} from '../ui/CheckboxField';
import {Def, IOption} from '../../utils/types';
import {IEnumFilter, IEnumLazyFilter} from '../../filterTypes';
import {isArray, isNumber, isShape, isString, or} from '../../utils/basicValidators';

export const EnumFilter: FC<{field: string; description: IEnumFilter | IEnumLazyFilter}> = ({field, description}) => {
  const locale = useLocale();
  const nextFilter = useNextFilter();
  const setNextFilter = useSetNextFilter();
  const filterValueAsSet = new Set(nextFilter[field]);
  const [options, setOptions] = useState<IOption<string | number>[]>([]);
  useEffect(() => {
    if (description.type === 'enum') {
      setOptions(description.options);
    } else if (description.type === 'enumLazy') {
      let aborted = false;
      description.getOptions().then((options) => {
        if (!aborted && isOptions(options)) {
          setOptions(options);
        }
      });
      return () => {
        aborted = true;
      };
    }
    return undefined;
  }, [description]);
  const selectAll = useCallback<Def<ICheckboxFieldProps['onChange']>>(
    (e) => {
      setNextFilter((prev) => {
        return {
          ...prev,
          [field]: e.checked ? options.map(({value}) => value) : []
        };
      });
    },
    [options, field, setNextFilter]
  );
  const onChange = useCallback<Def<ICheckboxFieldProps['onChange']>>(
    (e) => {
      setNextFilter((prev) => {
        const set = new Set(prev[field]);
        let value;
        const name = e.target.name;
        if (name.startsWith('n')) {
          value = Number(name.slice(1));
        } else {
          value = name.slice(1);
        }
        if (e.checked) {
          set.add(value);
        } else {
          set.delete(value);
        }
        return {
          ...prev,
          [field]: Array.from(set)
        };
      });
    },
    [field, setNextFilter]
  );
  return (
    <div css={[mainCss, widthCss]}>
      <div>
        <CheckboxField
          label={locale.selectAll}
          checked={options.every(({value}) => filterValueAsSet.has(value))}
          onChange={selectAll}
        />
      </div>
      <ul>
        {options.map(({label, value}) => {
          return (
            <li key={value}>
              <CheckboxField
                label={label}
                name={typeof value === 'number' ? `n${value}` : `s${value}`}
                checked={filterValueAsSet.has(value)}
                onChange={onChange}
              />
            </li>
          );
        })}
      </ul>
      <FilterActions field={field} />
    </div>
  );
};

// language=SCSS
const mainCss = css`
  & {
    padding: 0 calc(20rem / var(--bfs)) calc(20rem / var(--bfs));

    > div:first-of-type {
      padding: calc(20rem / var(--bfs)) 0;
      border-bottom: calc(1rem / var(--bfs)) solid var(--secondary-color);
      margin-bottom: calc(20rem / var(--bfs));
      color: var(--text-color-secondary);
      font-weight: 500;
    }

    > ul {
      max-height: calc(300rem / var(--bfs));
      overflow: auto;

      list-style: none;
      padding: 0;
      margin: 0;
      font-weight: 500;
      color: var(--text-color-secondary);

      position: relative;

      display: block;
      flex-wrap: wrap;

      > li {
        padding: 0;
        margin: 0 0 calc(20rem / var(--bfs)) 0;
      }
      > li:last-child {
        margin: 0;
      }
    }
  }
`;

const isOptions = isArray(
  isShape<IOption<string | number>>({label: isString, value: or(isString, isNumber)})
);
