/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, useCallback, useMemo} from 'react';
import {useColumns, usePreferences, useSetPreferences} from '../hooks/tableState';
import {CheckboxList} from './ui/CheckboxList';

export const GroupingColumns: FC = () => {
  const columns = useColumns();
  const {groupingColumnFields} = usePreferences();
  const setPreferences = useSetPreferences();
  const options = useMemo(() => {
    return columns.map(({field, header, isPinnedGroup}) => ({
      value: field,
      label: header,
      disabled: Boolean(isPinnedGroup)
    }));
  }, [columns]);
  const onChange = useCallback(
    (value, checked) => {
      setPreferences((prev) => {
        const set = new Set(prev.groupingColumnFields);
        if (checked) {
          set.add(value);
        } else {
          set.delete(value);
        }
        return {
          ...prev,
          groupingColumnFields: Array.from(set)
        };
      });
    },
    [setPreferences]
  );
  const onOrderChange = useCallback(
    (sourceIndex: number, targetIndex: number) => {
      setPreferences((prev) => {
        const arr = prev.groupingColumnFields.slice();
        const source = arr[sourceIndex];
        arr[sourceIndex] = arr[targetIndex];
        arr[targetIndex] = source;
        return {
          ...prev,
          groupingColumnFields: arr
        };
      });
    },
    [setPreferences]
  );
  return (
    <CheckboxList onOrderChange={onOrderChange} value={groupingColumnFields} options={options} onChange={onChange} />
  );
};
