/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {
  DOMAttributes,
  Fragment,
  ReactFragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { isString } from '../utils/basicValidators';
import { get } from 'lodash-es';
import { IColumn } from '../ITableProps';
import { createDisposers } from '../utils/disposers';
import { cx } from '../utils/cx';
import ResizeObserver from 'resize-observer-polyfill';

export type IGroupedRowData<T extends {}> = T & {
  $groupKey: string;
  $index: number;
};

export const useGroups = <T extends {}, K extends string>(
  rows: T[],
  groupFields: Array<IColumn<T, K>['field']>,
  cols: IColumn<{}, string>[]
) => {
  const [openGroups, setOpenGroups] = useState<Set<string>>(() => new Set());
  const toggleGroup = useCallback((groupKey: string) => {
    setOpenGroups((prev) => {
      const set = new Set(prev);
      if (set.has(groupKey)) {
        set.delete(groupKey);
      } else {
        set.add(groupKey);
      }
      return set;
    });
  }, []);
  const groupMap = useMemo(() => grouper(rows, groupFields, cols), [groupFields, rows, cols]);
  useEffect(() => {
    setOpenGroups((prev) => {
      const newSet = new Set<string>();
      prev.forEach((key) => {
        if (groupMap.has(key)) {
          newSet.add(key);
        }
      });
      return newSet;
    });
  }, [groupMap]);
  const groupedData = useMemo(() => {
    const acc: IGroupedRowData<T>[] = [];
    groupMap.forEach((value, key) => {
      if (openGroups.has(key)) {
        acc.push(...value.rows);
      } else {
        acc.push(value.rows[0]);
      }
    });
    return acc;
  }, [groupMap, openGroups]);
  return {
    openGroups,
    toggleGroup,
    groupMap,
    groupedData
  };
};

type IGroup<T extends {}> = {index: number; rows: IGroupedRowData<T>[]};
type IGroupMap<T extends {}> = Map<string, IGroup<T>>;
const makeKey = (values: Array<string>) => JSON.stringify(values);

const grouper = <T extends {}, K extends string>(
  rows: T[],
  groupFields: Array<IColumn<T, K>['field']>,
  cols: IColumn<{}, string>[]
): IGroupMap<T> => {
  const refCols = cols.filter((col) => typeof col.sortable === 'string');
  const groupFieldsWithRefs = groupFields.map((field) => {
    const refMatched = refCols.find((col) => col.field === field);
    return (refMatched?.sortable as K) || field;
  });
  let prevSubKey = '';
  let groupIndex = 0;
  const getGroupKey = () => `${groupIndex}:${prevSubKey}`;
  return rows.reduce((acc, rowData) => {
    const subKey = makeKey(groupFieldsWithRefs.map(getGroupingValue(rowData)).filter(isString));
    if (subKey === prevSubKey) {
      const $groupKey = getGroupKey();
      const group = acc.get($groupKey);
      if (!group) {
        throw Error('Unexpected state');
      } else {
        group.rows.push(
          Object.assign(rowData, {
            $groupKey,
            $index: group.rows.length
          })
        );
      }
    } else {
      prevSubKey = subKey;
      groupIndex++;
      const $groupKey = getGroupKey();
      acc.set($groupKey, {
        index: groupIndex,
        rows: [
          Object.assign(rowData, {
            $groupKey,
            $index: 0
          })
        ]
      });
    }
    return acc;
  }, new Map() as IGroupMap<T>);
};

let nextId = 0;
const getId = () => nextId++;
const getGroupingValue =
  <T extends {}, K extends string>(rowData: T) =>
  (columnName: IColumn<T, K>['field']): string => {
    const value = get(rowData, columnName);
    if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      return value.toString();
    }
    // TODO add key generator;
    return getId().toString();
  };

export const useExpansionState = () => {
  const [groupNodes, setGroupNodes] = useState<Map<string, {first?: HTMLElement; last?: HTMLElement}>>(() => new Map());
  useEffect(() => {
    const disposers = createDisposers();
    groupNodes.forEach((value) => {
      const {first, last} = value;
      // parent <- table
      const parent = first?.parentElement?.parentElement;
      if (first && last && parent) {
        const el = document.createElement('div');
        el.className = 'p-datatable-group-highlight';
        parent.style.setProperty('position', 'relative');
        parent.before(el);
        const update = () => {
          const {top: panelTop} = parent.getBoundingClientRect();
          const {top} = first.getBoundingClientRect();
          const {bottom} = last.getBoundingClientRect();

          const offset = top - panelTop;
          const height = bottom - top;

          el.style.setProperty('top', offset + 'px');
          el.style.setProperty('height', height + 'px');
        };
        const resizeOb = new ResizeObserver(() => {
          update();
        });
        resizeOb.observe(parent);
        disposers.add(() => {
          resizeOb.unobserve(parent);
          resizeOb.disconnect();
          parent.style.removeProperty('position');
          el.remove();
        });
      }
    });
    return disposers.flush;
  }, [groupNodes]);
  return setGroupNodes;
};

export function ExpansionRow<T>({
  event,
  group,
  onClick,
  open,
  setExpansionState,
  groupingFieldsLength
}: {
  event: IGroupedRowData<T>;
  group: IGroup<T>;
  onClick: DOMAttributes<HTMLButtonElement>['onClick'];
  open: boolean;
  setExpansionState: ReturnType<typeof useExpansionState>;
  groupingFieldsLength: number;
}) {
  const {$index, $groupKey} = event;
  const {length} = group.rows;
  const first = $index === 0;
  const last = $index === length - 1;
  const ref = useRef<HTMLButtonElement | null>(null);
  useLayoutEffect(() => {
    if (!open) {
      return undefined;
    }
    const row = ref.current?.parentElement?.parentElement;
    if (!row) {
      return undefined;
    }
    if (first) {
      setExpansionState((prev) => {
        const map = new Map(prev);
        const value = map.get($groupKey);
        if (value) {
          map.set($groupKey, {...value, first: row});
        } else {
          map.set($groupKey, {first: row});
        }
        return map;
      });
    } else if (last) {
      setExpansionState((prev) => {
        const map = new Map(prev);
        const value = map.get($groupKey);
        if (value) {
          map.set($groupKey, {...value, last: row});
        } else {
          map.set($groupKey, {last: undefined});
        }
        return map;
      });
    }

    row.classList.add('p-highlight-in-group');
    if (first) {
      row.classList.add('p-first');
    }
    if (last) {
      row.classList.add('p-last');
    }

    const els: HTMLElement[] = [];
    if (!first) {
      els.push(row.lastElementChild as HTMLElement);
      let current = row.firstElementChild;
      for (let i = 0; i < groupingFieldsLength; i++) {
        if (!(current instanceof HTMLElement)) {
          break;
        }
        els.push(current);
        current = current.nextElementSibling;
      }
    }
    els.forEach((el) => {
      el.dataset.hidden = 'true';
    });
    return () => {
      if (first) {
        setExpansionState((prev) => {
          const map = new Map(prev);
          const value = map.get($groupKey);
          if (value) {
            map.set($groupKey, {...value, first: undefined});
          } else {
            map.set($groupKey, {first: undefined});
          }
          return map;
        });
      }
      if (last) {
        setExpansionState((prev) => {
          const map = new Map(prev);
          const value = map.get($groupKey);
          if (value) {
            map.set($groupKey, {...value, last: undefined});
          } else {
            map.set($groupKey, {last: undefined});
          }
          return map;
        });
      }
      row.classList.remove('p-highlight-in-group');
      if (first) {
        row.classList.remove('p-first');
      }
      if (last) {
        row.classList.remove('p-last');
      }
      els.forEach((el) => {
        delete el.dataset.hidden;
      }); 
    };
  }, [$groupKey, open, first, last, setExpansionState, groupingFieldsLength]);

  let children: ReactFragment | null = null;
  let style: any = {display: 'none'};
  if (length > 1 && $index === 0) {
    children = (
      <Fragment>
        <i className={cx(['mdi', 'mdi-menu-down', open && 'mdi-rotate-180'])} />
        <span>{length}</span>
      </Fragment>
    );
    style = undefined;
  }
  return (
    <button ref={ref} css={expansionCss} onClick={onClick} style={style}>
      {children}
    </button>
  );
}

// language=SCSS
const expansionCss = css`
  & {
    cursor: pointer;

    display: flex;
    align-items: center;
    width: 100%;

    border: none;
    background: none;
    padding: 0 0 0 calc(10rem / var(--bfs));
    margin: 0;
    color: var(--text-color-secondary);

    i {
      display: flex;
      margin-right: calc(15rem / var(--bfs));
    }
    i::before {
      transition: transform var(--transition-duration) ease;
    }

    :focus {
      outline: none;
    }
  }
`;
