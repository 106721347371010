/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, useCallback, useMemo} from 'react';
import {useColumns, usePreferences, useSetPreferences} from '../hooks/tableState';
import {CheckboxList} from './ui/CheckboxList';

export const SelectedColumns: FC = () => {
  const columns = useColumns();
  const {selectedColumnFields} = usePreferences();
  const setPreferences = useSetPreferences();
  const options = useMemo(() => {
    return columns.map(({field, header, requiredColumn}) => ({
      value: field,
      label: header,
      disabled: !!requiredColumn
    }));
  }, [columns]);
  const onChange = useCallback(
    (value, checked) => {
      setPreferences((prev) => {
        const set = new Set(prev.selectedColumnFields);
        if (checked) {
          set.add(value);
        } else {
          set.delete(value);
        }
        return {
          ...prev,
          selectedColumnFields: Array.from(set)
        };
      });
    },
    [setPreferences]
  );
  return <CheckboxList value={selectedColumnFields} options={options} onChange={onChange} />;
};
