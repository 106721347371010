import {isArray, IShapeValidators, isNull, isNumber, isString, or, refine, isShape} from './utils/basicValidators';
import {defaults} from 'lodash-es';
import {IColumn} from './ITableProps';

export type IPage<K extends string> = {
  // offset; should be multiple of rows
  first: number;
  // current number of rows per page
  rows: number;
  // possible options for rows per page
  rowsPerPage: number[];
  // -1 for asc, 1 for desc
  sortOrder: number;
  sortField: K | null;
  invariableSortField?: string;
  invariableFilterQuery?: invariableFilterQuery;
};

export type invariableFilterQuery = {
  value: string | string[];
  operator: QueryOperators;
  field: string;
};

export enum QueryOperators {
  '~=' = '~=',
  '==' = '==',
  ':' = ':',
  '!=' = '!=',
  '>' = '>',
  '>=' = '>=',
  '<' = '<',
  '<=' = '<='
}

export const getPageDefaults = <K extends string>(
  options: Pick<IPage<K>, 'sortField' | 'rowsPerPage' | 'rows'> &
    Partial<Pick<IPage<K>, 'first' | 'sortOrder' | 'invariableSortField' | 'invariableFilterQuery'>>
): IPage<K> =>
  defaults({}, options, {
    first: 0,
    sortOrder: 1
  });

export const createPageValidators = <K extends string>(columns: IColumn<any, K>[]): IShapeValidators<IPage<K>> => {
  const columnNamesSet = new Set(columns.map(({field}) => field));
  return {
    first: isNumber,
    rows: isNumber,
    rowsPerPage: isArray(isNumber),
    sortOrder: isNumber,
    sortField: or(
      isNull,
      refine<K>(isString, (v) => columnNamesSet.has(v))
    ),
    invariableSortField: isString,
    invariableFilterQuery: isShape<invariableFilterQuery>({
      field: isString,
      operator: isString,
      value: or(isString, isArray(isString))
    })
  };
};
