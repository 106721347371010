import {
  IEnumFilter,
  IFilter,
  IFilterDescription,
  IFilterFieldType,
  IRangeFilter,
  isAutoCompleteFilter,
  ISearchFilter,
  isEnumFilter,
  isEnumLazyFilter,
  isRangeFilter,
  isSearchFilter,
  isTextFilter,
  isTimeFilter,
  ITextFilter,
  ITimeFilter
} from './filterTypes';
import {entries} from './utils/entries';
import {isFunction, isNumber, isString} from './utils/basicValidators';
import { ISearchQueryPayload } from '@netvision/lib-api-repo';

export const createComposeQ = <K extends string, FD extends IFilterDescription<K>>(description: FD) => {
  const composers = entries(description).reduce<Partial<IComposers<K, typeof description>>>((acc, [key, value]) => {
    let c: IComposer<any> | null = null;
    if (isTextFilter(value) || isAutoCompleteFilter(value)) {
      c = composeMatch;
    } else if (isRangeFilter(value) || isTimeFilter(value)) {
      c = composeRange;
    } else if (isEnumFilter(value) || isEnumLazyFilter(value) || isSearchFilter(value)) {
      c = composeEnum;
    }
    if (c === null) {
      console.error('Unknown description type:', key, value);
    } else {
      const field = value?.field ?? (key as K);
      Object.assign(acc, {[key]: c(field)});
    }
    return acc;
  }, {});
  return <F extends IFilter<K, FD>>(filter: F): ISearchQueryPayload[] | undefined => {
    const chunks: ISearchQueryPayload[] = [];
    Object.entries(filter).forEach(([key, value]) => {
      if (key in composers) {
        const c = composers[key as K];
        if (isFunction<ReturnType<IComposer<F[K]>>>(c)) {
          const chunk = c(value as F[K]);
          if (chunk instanceof Object) {
            chunks.push(chunk);
          }
        }
      } else {
        console.error('Filter contains unexpected key:', key);
      }
    });
    return chunks.length === 0 ? undefined : chunks
  };
};

type IComposer<T> = (field: string) => (value: T) => ISearchQueryPayload | undefined;

type IComposers<K extends string, FD extends IFilterDescription<K>> = {
  [P in K]: FD[P] extends ITextFilter
    ? IComposer<IFilterFieldType<ITextFilter>>
    : FD[P] extends IRangeFilter
    ? IComposer<IFilterFieldType<IRangeFilter>>
    : FD[P] extends ITimeFilter
    ? IComposer<IFilterFieldType<ITimeFilter>>
    : FD[P] extends IEnumFilter
    ? IComposer<IFilterFieldType<IEnumFilter>>
    : FD[P] extends ISearchFilter
    ? IComposer<IFilterFieldType<ISearchFilter>>
    : never;
};

const composeEnum: IComposer<Array<string | number>> = (field) => (value) => {
  if (value.length === 0) {
    return undefined;
  } else {
    return {
      key: field,
      operator: '==',
      sanitize: true,
      value: value.map((v) => (
        isString(v) ? v : (isValidNumber(v) && isNotInfinity(v)) ? String(v) : undefined
      ))
        .filter(isString)
        .join(',')
    }
  }
};

const composeMatch: IComposer<string> = (field) => (value) => {
  if (value === '') {
    return undefined;
  } else {
    return {
      key: field,
      operator: '~=',
      insensitify: true,
      sanitize: true,
      value
    }
  }
};

const composeRange: IComposer<number[]> = (field) => (arr) => {
  const filterPayload: ISearchQueryPayload = {
    key: field,
    operator: '==',
    value: null
  }
  
  if (arr.length === 0) {
    return undefined;
  } else if (arr.length === 1) {
    const [value] = arr;

    if (isValidNumber(value) && isNotInfinity(value)) {
      filterPayload.value = value
      return filterPayload
    } else if (isNaN(value)) {
      filterPayload.value = null
      return filterPayload
    }
    return undefined;
  } else if (isValidNumber(arr[0]) && isValidNumber(arr[1])) {
    const [min, max] = arr.slice().sort();
    if (min === -Infinity && max === Infinity) {
      return undefined;
    } else if (min === -Infinity) {
      filterPayload.operator = '<='
      filterPayload.value = max
      return filterPayload
    } else if (max === Infinity) {
      filterPayload.operator = '>='
      filterPayload.value = min
      return filterPayload
    } else {
      filterPayload.value = `${min}..${max}`
      return filterPayload
    }
  } else if (isNaN(arr[0]) && isNaN(arr[1])) {
    filterPayload.value = null
    return filterPayload
  }
  return undefined;
};

const isValidNumber = (v: unknown): v is number => isNumber(v) && !isNaN(v);
const isNotInfinity = (v: number): v is number => Infinity !== v && -Infinity !== v;
