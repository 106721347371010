import {IColumn, IColumnVisibility} from './ITableProps';
import {defaultsDeep, isObjectLike, omit} from 'lodash-es';

export type IColumnsSettings<K extends string> = {
  [P in K]?: IColumnVisibility & {disabled?: true};
};

export const applyColumnsSettings = <T extends {}, K extends string>(
  allSortable: boolean,
  defaults: IColumnsSettings<K>,
  overrides: IColumnsSettings<K> = {},
  columns: IColumn<T, K>[]
): IColumn<T, K>[] => {
  const settings = defaultsDeep({}, overrides, defaults);
  return columns
    .map((column) => {
      column.sortable = allSortable;
      return column;
    })
    .filter((column) => {
      if (column.field in settings) {
        return !settings[column.field]?.disabled;
      } else {
        return true;
      }
    })
    .map((column) => {
      if (column.field in settings) {
        const value = settings[column.field];
        if (isObjectLike(value)) {
          return {
            ...column,
            ...omit(value, ['disabled'])
          };
        }
      }
      return column;
    });
};
