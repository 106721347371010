/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useCallback, useEffect} from 'react';
import {Calendar, CalendarProps} from 'primereact/calendar';
import {useNextFilter, useSetNextFilter} from '../../hooks/nextFilter';
import {IFilterFieldType, ITimeFilter} from '../../filterTypes';
import {useLocale} from '../../hooks/locale';
import {isArray, isDate, isNull, isNumber, or, refine} from '../../utils/basicValidators';
import {FilterActions} from '../FilterActions';
import {ToggleButton} from 'primereact/togglebutton';
import {isEqual} from 'lodash-es';

type ISetValue = (setter: (prev: IRange) => IRange) => void;

export function TimeFilter<D extends ITimeFilter, V extends IFilterFieldType<D>>({
  field,
  description
}: {
  field: string;
  description: D;
}) {
  const {min, max} = description;
  const nextFilter = useNextFilter();
  const setNextFilter = useSetNextFilter();
  const normalized = normalize(nextFilter[field] as V);
  const setValue = useCallback(
    (setter: (prev: IRange) => IRange) => {
      setNextFilter((prev) => ({
        ...prev,
        [field]: setter(prev[field] as V)
      }));
    },
    [field, setNextFilter]
  );
  useEffect(() => {
    if (normalized === undefined) {
      setValue(() => [-Infinity, Infinity]);
    } else {
      const [start, end] = normalized;
      const left = typeof min === 'number' ? min : min === 'now' ? Date.now() : -Infinity;
      const right = typeof max === 'number' ? max : max === 'now' ? Date.now() : Infinity;
      if (start !== -Infinity && end !== Infinity) {
        const bounded = normalized.map((v) => Math.max(left, Math.min(v, right))) as IRange;
        if (!isEqual(bounded, normalized)) {
          setValue(() => bounded);
        }
      }
    }
  }, [min, max, normalized, setValue]);
  if (normalized === undefined) {
    return null;
  }
  return (
    <div css={$main}>
      <DatePicker value={normalized} min={min} max={max} setValue={setValue} />
      <TimePicker value={normalized} setValue={setValue} />
      <Buttons value={normalized} setValue={setValue} />
      <FilterActions field={field} />
    </div>
  );
}

// language=SCSS
const $main = css`
  & {
    padding: var(--spacer);
  }
`;

const DatePicker: FC<{min?: number | 'now'; max?: number | 'now'; value: IRange; setValue: ISetValue}> = ({
  value,
  min,
  max,
  setValue
}) => {
  const locale = useLocale();
  const dateRange = value ? rangeToDateRange(value) : null;
  return (
    <Calendar
      css={$calendar}
      inline
      value={
        (dateRange === null ? null : isSameDay(dateRange[0], dateRange[1]) ? [dateRange[0], null] : dateRange) as any
      }
      locale={locale.datePicker}
      dateFormat={'dd.mm.yy'}
      selectionMode={'range'}
      minDate={min === undefined ? min : min === 'now' ? new Date() : new Date(min)}
      maxDate={max === undefined ? max : max === 'now' ? new Date() : new Date(max)}
      onChange={useCallback(
        (e) => {
          const value = e.value as unknown;
          setValue((prev) => {
            const [prevStart, prevEnd] = prev;
            if (!isDateRange(value) || value === null) {
              return [-Infinity, Infinity];
            } else if (value[1] === null || isSameDay(value[0], value[1])) {
              return [
                setSameHours(value[0], prevStart > -Infinity ? prevStart : minTime(value[0])).getTime(),
                setSameHours(value[0], prevEnd < Infinity ? prevEnd : maxTime(value[0])).getTime()
              ];
            } else {
              return [
                setSameHours(value[0], prevStart > -Infinity ? prevStart : minTime(value[0])).getTime(),
                setSameHours(value[1], prevEnd < Infinity ? prevEnd : maxTime(value[0])).getTime()
              ];
            }
          });
        },
        [setValue]
      )}
    />
  );
};

// language=SCSS
const $calendar = css`
  & {
    .p-datepicker {
      padding: 0;
      table {
        margin-bottom: var(--spacer-xs);
      }
    }
  }
`;

const TimePicker: FC<{value: IRange; setValue: ISetValue}> = ({value, setValue}) => {
  const dateRange = value ? rangeToDateRange(value) : null;
  const start = dateRange?.[0];
  const end = dateRange?.[1];
  return (
    <div css={$time}>
      <div>
        <Calendar
          disabled={!isDate(start)}
          inline={true}
          timeOnly={true}
          hourFormat={'24'}
          value={start || minTime(new Date())}
          onChange={useCallback(
            (e) => {
              const value = e.value;
              if (isDate(value)) {
                setValue((prev) => [value.getTime(), prev[1]]);
              }
            },
            [setValue]
          )}
        />
      </div>
      <div>{'-'}</div>
      <div>
        <Calendar
          disabled={!isDate(end)}
          inline={true}
          timeOnly={true}
          hourFormat={'24'}
          value={end || maxTime(new Date())}
          onChange={useCallback(
            (e) => {
              const value = e.value;
              if (isDate(value)) {
                setValue((prev) => [prev[0], value.getTime()]);
              }
            },
            [setValue]
          )}
        />
      </div>
    </div>
  );
};

// language=SCSS
const $time = css`
  & {
    color: var(--text-color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;

    .p-datepicker.p-datepicker-timeonly {
      padding: 0;
      width: auto;
      &.p-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  & > div:nth-child(2) {
    text-align: center;
    width: var(--spacer-sm);
  }
`;

const DAY = 24 * 3600 * 1000;
const isExactLength = (days: number, base: Date, date: IRange): boolean => {
  if (!isSameDay(base, date[1])) {
    return false;
  }
  const start = minTime(date[0]).getTime();
  const end = maxTime(date[1]).getTime();
  return days === Math.floor((end - start) / DAY);
};

const isOffset = (offset: number, base: Date, date: IRange): boolean => {
  return isSameDay(date[0], date[1]) && isSameDay(withPastOffset(offset, base), date[0]);
};

const makeRange = (days: number, end: number): IRange => {
  return [minTime(end - Math.max(0, DAY * days)).getTime(), end];
};

const withPastOffset = (offset: number, date: Date): Date => {
  return minTime(minTime(date).getTime() - offset);
};

const Buttons: FC<{value: IRange; setValue: ISetValue}> = ({value, setValue}) => {
  const locale = useLocale();
  const onChangeWithRange = (days: number) => ({value}: {value: boolean}) => {
    if (value) {
      setValue(() => makeRange(days, Date.now()));
    } else {
      setValue(() => [-Infinity, Infinity]);
    }
  };
  const onChangeWithOffset = (days: number) => ({value}: {value: boolean}) => {
    if (value) {
      setValue(() => {
        const date = withPastOffset(days * DAY, new Date()).getTime();
        return [date, maxTime(date).getTime()];
      });
    } else {
      setValue(() => [-Infinity, Infinity]);
    }
  };
  const now = new Date();
  return (
    <div css={$buttons}>
      <ToggleButton
        checked={isExactLength(31, now, value)}
        className={'p-button-sm'}
        onLabel={locale.calendarButtons.month}
        offLabel={locale.calendarButtons.month}
        onChange={onChangeWithRange(31)}
      />
      <ToggleButton
        checked={isExactLength(7, now, value)}
        className={'p-button-sm'}
        onLabel={locale.calendarButtons.week}
        offLabel={locale.calendarButtons.week}
        onChange={onChangeWithRange(7)}
      />
      <ToggleButton
        checked={isOffset(1, now, value)}
        className={'p-button-sm'}
        onLabel={locale.calendarButtons.yesterday}
        offLabel={locale.calendarButtons.yesterday}
        onChange={onChangeWithOffset(1)}
      />
      <ToggleButton
        checked={isOffset(0, now, value)}
        className={'p-button-sm'}
        onLabel={locale.calendarButtons.today}
        offLabel={locale.calendarButtons.today}
        onChange={onChangeWithOffset(0)}
      />
    </div>
  );
};

// language=SCSS
const $buttons = css`
  & {
    margin-top: var(--spacer-sm);

    display: flex;
    justify-content: space-between;

    > .p-button > .p-button-label {
      font-weight: 400;
    }
  }
`;

const minTime = (date: Date | number) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

const maxTime = (date: Date | number) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

const setSameHours = (target: Date | number, source: Date | number) => {
  target = new Date(target);
  source = new Date(source);
  target.setHours(source.getHours(), source.getMinutes(), source.getSeconds(), source.getMilliseconds());
  return target;
};

const isSameDay = (a: Date | number, b: Date | number) => minTime(a).getTime() === minTime(b).getTime();

type IRange = [number, number];
type IDateRange = [Date, Date] | null;

const isRange = refine(isArray(isNumber), (v): v is IRange => v.length === 2 && v[0] <= v[1]);
const isDateRange = or(
  isNull,
  refine(isArray(or(isDate, isNull)), (v): v is Exclude<IDateRange, null> => v.length === 2 && isDate(v[0]))
);

const normalize = (value: unknown): IRange | undefined => {
  if (isRange(value)) {
    return value;
  } else {
    return undefined;
  }
};

const rangeToDateRange = (value: IRange): IDateRange => {
  const [v1, v2] = value;
  if (v1 !== -Infinity && v2 !== Infinity) {
    return [new Date(v1), new Date(v2)];
  }
  return null;
};
