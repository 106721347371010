import {isArray, isBoolean, IShapeValidators, isString, IValidator} from './utils/basicValidators';
import {IColumn} from './ITableProps';

export type IPreferences<K extends string> = {
  groupingIsActive: boolean;
  selectedColumnFields: K[];
  groupingColumnFields: K[];
};

export type IPreferencesValidators<K extends string> = IShapeValidators<IPreferences<K>>;

export const createPreferencesValidator = <K extends string>(): IPreferencesValidators<K> => ({
  groupingIsActive: isBoolean,
  selectedColumnFields: isArray(isString as IValidator<K>),
  groupingColumnFields: isArray(isString as IValidator<K>)
});

export const createPreferencesDefaults = <T extends {}, K extends string>(
  columns: IColumn<T, K>[],
  defaultGroupingFields: K[] = []
): IPreferences<K> => ({
  groupingIsActive: true,
  selectedColumnFields: columns.filter((col) => col.requiredColumn || !col.hideByDefault).map((col) => col.field),
  groupingColumnFields: defaultGroupingFields
});

export const savePreferences = <K extends string>(localStorageKey: string, preferences: IPreferences<K>): void => {
  window.localStorage.setItem(localStorageKey, JSON.stringify(preferences));
};

export const readPreferences = (localStorageKey: string): {[P in string]: unknown} => {
  const str = window.localStorage.getItem(localStorageKey) || '{}';
  try {
    const res: unknown = JSON.parse(str);
    if (typeof res === 'object' && res !== null) {
      return res as {};
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};
