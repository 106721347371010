/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';
import {createIconSizesCss} from './createIconSizesCss';

const fillTextColorCss = css`
  & {
    fill: var(--text-color-secondary);
  }
`;

const sizesCss = createIconSizesCss(20, 20);

export const Close: FC<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <div css={sizesCss}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99992 11.2107L5.3658 16.0007L4 14.589L8.4432 9.99635L4 5.40374L5.3658 4L9.99992 8.78995L14.634 4L15.9998 5.40374L11.5566 9.99635L15.9998 14.589L14.634 16.0007L9.99992 11.2107Z"
          fill="#959DA8"
          css={fillTextColorCss}
        />
      </svg>
    </div>
  );
};
