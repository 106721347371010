/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {CheckboxField, ICheckboxFieldProps} from './CheckboxField';
import {FC, useCallback, useMemo} from 'react';

type IOption = {
  value: string;
  label: string;
  disabled: boolean;
};

export type ICheckboxListProps = {
  value: string[];
  options: IOption[];
  onChange: (value: string, checked: boolean) => void;
  onOrderChange?: (sourceIndex: number, targetIndex: number) => void;
};

export const CheckboxList: FC<ICheckboxListProps> = ({value, options, onChange, onOrderChange}) => {
  const draggable = !!onOrderChange;
  const valueSet = useMemo(() => new Set(value), [value]);
  const optionsMap = useMemo(() => new Map(options.map((opt) => [opt.value, opt])), [options]);
  const handleChange = useCallback<Exclude<ICheckboxFieldProps['onChange'], undefined>>(
    (e) => onChange(e.target.name, e.checked),
    [onChange]
  );
  const onDragStart = useCallback((e) => {
    e.dataTransfer.setData('text/plain', e.currentTarget.getAttribute('data-index'));
    e.dataTransfer.dropEffect = 'move';
  }, []);
  const onDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);
  const onDrop = useCallback(
    (e) => {
      e.preventDefault();
      const currentIndex = parseInt(e.currentTarget.getAttribute('data-index'), 10);
      const droppedIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
      const disabledOptionsCount = options.reduce((acc, next) => {
        if (next.disabled) acc += 1;
        return acc;
      }, 0);

      if (disabledOptionsCount <= currentIndex && currentIndex !== droppedIndex) {
        onOrderChange?.(droppedIndex, currentIndex);
      }
    },
    [onOrderChange, options]
  );
  return (
    <div css={$list}>
      {!draggable && (
        <ul>
          {options.map(({value, label, disabled}) => {
            return (
              <li key={value}>
                <CheckboxField
                  label={label}
                  name={value}
                  disabled={disabled}
                  checked={valueSet.has(value)}
                  onChange={handleChange}
                  labelHeight={'1em'}
                />
              </li>
            );
          })}
        </ul>
      )}
      {draggable && (
        <ul>
          {value.map((val, index) => {
            const opt = optionsMap.get(val);
            if (!opt) return null;
            const {value, label, disabled} = opt;
            return (
              <li
                key={value}
                data-index={index}
                draggable={!disabled && draggable}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDrop={onDrop}>
                {!disabled && <i className={'mdi mdi-drag-horizontal-variant'} />}
                <CheckboxField
                  label={label}
                  name={value}
                  disabled={disabled}
                  checked={true}
                  onChange={handleChange}
                  labelHeight={'1em'}
                />
              </li>
            );
          })}
        </ul>
      )}
      {draggable && (
        <ul>
          {options
            .filter(({value}) => !valueSet.has(value))
            .map(({value, label, disabled}) => {
              return (
                <li key={value}>
                  <CheckboxField
                    label={label}
                    name={value}
                    disabled={disabled}
                    checked={false}
                    onChange={handleChange}
                    labelHeight={'1em'}
                  />
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

const $list = css`
  max-width: calc(1024rem / var(--bfs));

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    position: relative;

    display: flex;
    flex-wrap: wrap;

    > li {
      width: calc(100% / 5 - var(--spacer-xs));
      padding: 0;
      margin: 0 var(--spacer-xs) var(--spacer) 0;

      position: relative;

      &[draggable='true'] {
        touch-action: none;
      }

      &[draggable='true'],
      &[draggable='true'] label {
        cursor: grab;
      }

      &[draggable='true']:active,
      &[draggable='true']:active label {
        cursor: grabbing;
      }

      &[draggable='true'] > i {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        height: var(--spacer-sm);
        width: var(--spacer-sm);
        align-items: center;
        justify-content: center;

        opacity: 0;
        transition: opacity var(--transition-duration) ease;
      }
      &[draggable='true']:hover > i {
        opacity: 1;
      }
    }
  }
`;
